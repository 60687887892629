<template>
    <div class="">
        <nav id="header" class="w-full h-full">
            <div class="flex newSty_bg w-full h-full">
                    <div class="flex items-center w-full" name="expand">
                        <a href="#" class="hamburger_icon absolute cursor-pointer z-6 ml-2" @click="onToggleSideBar">
                            <svg class="hamburgerTandD" xmlns="http://www.w3.org/2000/svg" :class="{ activeHamburger: isSideBar }" width="20" height="15" viewBox="0 0 20 15"  fill="none">
                                <path d="M0 6.25H15V8.75H0V6.25ZM0 0H20V2.5H0V0ZM0 15H9.04375V12.5H0V15Z" fill="#B4B5BA"/>
                            </svg>
                        </a>
                        <transition>
                            <nav id="header" class="w-full">
                                <div class="flex justify-center align-center" >
                                        <div class="flex justify-center items-center relative" style="gap: 10px;">

                                          <!-- ZOOM -->
                                          <div class="flex w-full items-center mx-2">
                                            <label class="text-sx whitespace-nowrap">ZOOM IN / OUT</label>
                                            <input
                                              type="checkbox"
                                              class="checkbox hidden"
                                              :id="'td_zoomOnOff' + this.widgetId"
                                              @click="zoom_toggle()"
                                              :checked="isZoom"
                                            />
                                            <label
                                              :for="'td_zoomOnOff' + this.widgetId"
                                              class="checkbox-label td_checkbox_zoom ml-1"
                                            >
                                              <span class="ball"></span>
                                            </label>
                                          </div>

                                          <!-- TOOL TIP -->
                                          <div class="flex w-full items-center mx-2">
                                            <label class="text-sx whitespace-nowrap">TOOL TIP</label>
                                            <input
                                              type="checkbox"
                                              class="checkbox hidden"
                                              :id="'td_tooltip' + this.widgetId"
                                              @click="tooltipOn()"
                                              :checked="isTooltip"
                                            />
                                            <label
                                              :for="'td_tooltip' + this.widgetId"
                                              class="checkbox-label td_checkbox_zoom ml-1"
                                            >
                                              <span class="ball"></span>
                                            </label>
                                          </div>

                                          <!-- PRINT BUTTON -->
                                          <button
                                            class="btnStyles fontSize text-white uppercase mx-2 px-2 py-1"
                                            type="button"
                                            @click="print()"
                                          >
                                            Print
                                          </button>

                                          <!-- REPLAY PAUSE, START -->
                                          <div class="flex w-full items-center mx-2" v-if="isReplay">
                                            <label class="text-sx whitespace-nowrap">PAUSE</label>
                                            <input
                                              type="checkbox"
                                              class="checkbox hidden"
                                              :id="'td_replayPauseStart' + this.widgetId"
                                              @click="replayPauseStartToggle($event.target.checked)"
                                              :checked="true"
                                            />
                                            <label
                                              :for="'td_replayPauseStart' + this.widgetId"
                                              class="checkbox-label td_checkbox_zoom mx-1"
                                            >
                                              <span class="ball"></span>
                                            </label>
                                            <label class="text-sx whitespace-nowrap">START</label>
                                          </div>
                                        </div>
                                    
                                </div>
                            </nav>
                        </transition>
                    </div>
                
            </div>
        </nav>
        </div>
</template>
<script>
  export default {
    props: {
      onToggleSideBar: Function,
      onSave: Function,
      displayId: String,
      widgetId: String,
      isSideBar: Boolean,
      zoom_toggle: Function,
      isZoom: Boolean,
      tooltipOn: Function,
      replayPauseStartToggle: Function,
      isTooltip: Boolean,
      isReplay: {type:Boolean, default: false},
      print: Function,
    },
    data() {
      return {
        isHamburgerClickedTandD: false,
      };
    },
  };
  </script>
  <style scoped>
  .hamburgerTandD path {
    /* fill: var(--defalutHamburger); */
    fill: var(--textColor)
  }
  /* .ActiveHamburgerTandD path {
    /* fill: var(--activeHamburger); 
    fill: #fff;
  } */
  .hamburgerTandD.activeHamburger path{
    fill: var(--activeTextColor)
  }
  .btnStyles {
      border: 1px solid var(--activeTextColor);
      color: var(--activeTextColor);
      border-radius: 3px;
  }
  .fontSize {
      font-weight: 400;
      font-size: calc(.2vw + .2vh + .65vmin) !important;
  }
  .newSty_bg {
      background: var(--central_bg) !important;
      box-shadow: 0 4px 10px 4px rgba(0, 0, 0, .15) !important;
  }
  </style>
